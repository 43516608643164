<template>
  <base-layout :loadingFlag="$store.state.loading">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <span class="icon is-small">
              <font-awesome-icon icon="home" />
            </span>
            <span>Home</span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'makePayment', params: { id: $route.params.id } }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="file-invoice-dollar" />
            </span>
            <span>Make Payment</span></router-link
          >
        </li>
        <li class="is-active">
          <router-link
            :to="{
              name: 'schedule',
              params: { id: $route.params.id }
            }"
          >
            <span class="icon is-small">
              <font-awesome-icon icon="calendar" />
            </span>
            <span>Schedule</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="content">
      <user-info />
    </div>
    <hr class="is-marginless" />
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <h1 class="title is-4">Schedule</h1>
        <div class="content">
          <payment-arrangements :id="$route.params.id" />
        </div>
        <div class="content">
          <div class="buttons">
            <router-link
              :to="{ name: 'makePayment', params: { id: $route.params.id } }"
              class="button"
              >Back</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import axios from "axios";
import AccountHeader from "@/components/AccountHeader.vue";
import UserInfo from "@/components/UserInfo.vue";
import Sidebar from "@/components/Sidebar.vue";
import PaymentArrangements from "@/components/PaymentArrangements.vue";

export default {
  components: {
    BaseLayout,
    AccountHeader,
    UserInfo,
    Sidebar,
    PaymentArrangements
  },
  data() {
    return {
      transactions: []
    };
  },
  computed: {
    tableHeaders() {
      if (this.transactions == null || this.transactions.length == 0) return [];
      else
        return Object.values(this.transactions[0]).filter(
          t =>
            t.label == "DATE" || t.label == "AMOUNT" || t.label == "DESCRIPTION"
        );
    }
  },
  methods: {
    async fetchTransactions() {
      this.$store.commit("SET_LOADING", true);
      try {
        let dbtNo = this.$route.params.id;
        let { data } = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/transactions/" +
            dbtNo +
            ";user=" +
            this.$store.state.username,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt
            }
          }
        );
        this.transactions = data;
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error Fetching Transactions; ${e}`,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.fetchTransactions());
  }
};
</script>
